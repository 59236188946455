
import { Options, Vue } from "vue-class-component";
import BotItemHeader from "./BotItemHeader.vue";
import BotLog from "./BotLog.vue";
import Top from "@/components/widgets/Top/Top.vue";
import BotParams from "./BotParams.vue";

enum Tab {
  log,
  params,
}

@Options({
  components: { BotItemHeader, BotLog, Top, BotParams },
})
export default class BotItem extends Vue {
  private Tab = Tab;
  private tabDict = [
    { id: Tab.log, name: "日志" },
    { id: Tab.params, name: "参数" },
  ];
  private currentTabId = Tab.log;

  private onClickedTab(tabId: Tab) {
    this.currentTabId = tabId;
  }
}
