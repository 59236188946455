
import { Options, Vue } from "vue-class-component";
import Top from "@/components/widgets/Top/Top.vue";
import VqButton from "@/components/widgets/VqButton/VqButton.vue";

@Options({
  components: { Top, VqButton },
})
export default class BotCheckList extends Vue {
  private list = [
    {
      id: 0,
      icon: "lock",
      title: "支付密码",
      subTitle: "设置支付密码",
      finished: false,
    },
    {
      id: 1,
      icon: "market",
      title: "量化策略",
      subTitle: "租赁量化策略",
      finished: true,
    },
    {
      id: 2,
      icon: "api",
      title: "API Key",
      subTitle: "绑定交易所API Key",
      finished: false,
    },
  ];
}
