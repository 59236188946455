
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { LogType } from "@/config/enum";

@Options({
  components: {},
})
export default class BotLogItem extends Vue {
  @Prop({ type: Number, default: LogType.buy }) private logType!: LogType;

  private LogType = LogType;

  private get ActionClassName(): any {
    const classObj: any = {};
    const logType = this.logType;
    switch (logType) {
      case LogType.buy:
        classObj["BotLogItemAction__label_buy"] = true;
        break;
      case LogType.sell:
        classObj["BotLogItemAction__label_sell"] = true;
        break;
      case LogType.restart:
        classObj["BotLogItemAction__label_restart"] = true;
        break;
      case LogType.error:
        classObj["BotLogItemAction__label_error"] = true;
        break;
      case LogType.stop:
      default:
        classObj["BotLogItemAction__label_stop"] = true;
    }
    return classObj;
  }
  private get ActionLabel(): string {
    const logType = this.logType;
    switch (logType) {
      case LogType.buy:
        return "买入";
      case LogType.sell:
        return "卖出";
      case LogType.restart:
        return "重启";
      case LogType.error:
        return "错误";
      case LogType.stop:
      default:
        return "停止";
    }
  }
}
