
import { Options, Vue } from "vue-class-component";
import BotLogItem from "./BotLogItem.vue";
import { LogType } from "@/config/enum";
import LoadMore from "@/components/widgets/LoadMore/LoadMore.vue";

enum Tab {
  log,
  params,
}

@Options({
  components: { BotLogItem, LoadMore },
})
export default class BotLog extends Vue {
  private LogType = LogType;
  private Tab = Tab;
}
