import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BotLogItem = _resolveComponent("BotLogItem")!
  const _component_LoadMore = _resolveComponent("LoadMore")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BotLogItem, {
      logType: _ctx.LogType.buy
    }, null, 8, ["logType"]),
    _createVNode(_component_BotLogItem, {
      logType: _ctx.LogType.sell
    }, null, 8, ["logType"]),
    _createVNode(_component_BotLogItem, {
      logType: _ctx.LogType.restart
    }, null, 8, ["logType"]),
    _createVNode(_component_BotLogItem, {
      logType: _ctx.LogType.error
    }, null, 8, ["logType"]),
    _createVNode(_component_BotLogItem, {
      logType: _ctx.LogType.buy
    }, null, 8, ["logType"]),
    _createVNode(_component_BotLogItem, {
      logType: _ctx.LogType.restart
    }, null, 8, ["logType"]),
    _createVNode(_component_BotLogItem, {
      logType: _ctx.LogType.stop
    }, null, 8, ["logType"]),
    _createVNode(_component_BotLogItem, {
      logType: _ctx.LogType.buy
    }, null, 8, ["logType"]),
    _createVNode(_component_LoadMore)
  ]))
}