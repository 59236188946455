
import { Options, Vue } from "vue-class-component";
import Top from "@/components/widgets/Top/Top.vue";
import VqInput from "@/components/widgets/VqInput/VqInput.vue";
import VqButton from "@/components/widgets/VqButton/VqButton.vue";
import Popout from "@/components/widgets/Popout/Popout.vue";

enum Dialog {
  strategy,
  api,
  none,
}

@Options({
  components: { Top, VqInput, VqButton, Popout },
})
export default class BotCreate extends Vue {
  private Dialog = Dialog;
  private currentDialog = Dialog.none;

  private onOpenDialog(dialog: Dialog): void {
    this.currentDialog = dialog;
  }

  private onCloseDialog(): void {
    this.currentDialog = Dialog.none;
    return;
  }
}
