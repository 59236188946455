
import { Options, Vue } from "vue-class-component";
import { Emit } from "vue-property-decorator";

@Options({
  components: {},
})
export default class BotItemHeader extends Vue {
  @Emit("close") private onClose(): void {
    return;
  }
}
